import React, { useEffect } from 'react';

import { Field } from 'formik';
import FormikRadioGroup from '../../RadioGroup/RadioGroup';
import CheckboxGroup from '../../CheckboxGroup/CheckboxGroup';
import { CheckboxWithoutBinding } from '../../UI/Checkbox/Checkbox';

import styles from './PropertiesType.module.scss';

const PropertiesType = ({
  radioLabelClassName,
  contestField,
  // propertySettingField,
  propertyTypeField,
  isAllSelected,
  toggleIsAllSelect,
  onDeselect,
  values,
}) => {
  useEffect(() => {
    onDeselect(values?.[propertyTypeField.name]);
  }, [values]);

  return (
    <>
      <>
        <div className={styles.sectionSubtitle}>
          CONTEXT
        </div>
        <Field
          name={contestField.name}
          wrapperStyles={`${styles.radios}`}
          labelClassName={radioLabelClassName || styles.radiosLabel}
          options={contestField.options}
          component={FormikRadioGroup}
        />
      </>
      {/* <>
        <div className={styles.sectionSubtitle}>
          PROPERTY SETTING
        </div>
        <Field
          name={propertySettingField.name}
          wrapperStyles={styles.radios}
          labelClassName={radioLabelClassName || styles.radiosLabel}
          options={propertySettingField.options}
          component={FormikRadioGroup}
        />
      </> */}
      <>
        <div className={styles.sectionSubtitle}>
          PROPERTY TYPE FILTER
        </div>
        <div className={`${styles.selectAll}`}>
          <CheckboxWithoutBinding
            containerClasses={`
            c-custom
          `}
            checkboxClasses={`
            ${styles.checkbox}
            c-custom__check-bordered-primary
            c-custom__check-sm
            c-custom__custom-input
            c-custom__check
          `}
            labelClasses={`
            ${styles.checkboxSelectAll}
            ${styles.mb20}
            flex flex-v-center col-12 col-sm-6
            `}
            name="selectAll"
            onChange={() => toggleIsAllSelect(isAllSelected, propertyTypeField.name)}
            isChecked={isAllSelected}
          >
            <div className={`
            ${styles.checkboxText}
            ${styles.label}
            flex flex-v-center
          `}
            >
              {' '}
              Select All
              {' '}
            </div>
          </CheckboxWithoutBinding>
        </div>
        <div className={styles.checkboxesContainer}>
          <CheckboxGroup
            name={propertyTypeField.name}
            valueField="value"
            checkboxClassName={`${styles.checkbox}`}
            wrapperClassName={`${styles.checkboxDropdowned} col-12 col-sm-6`}
            titleClassName={`${styles.checkboxText} ${styles.label}`}
            options={propertyTypeField.options}
          />
        </div>
      </>
    </>
  );
};

export default PropertiesType;
