import React from 'react';

import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import { noop } from '../../../helpers/helper-methods';

import styles from './HomeTabCardModal.module.scss';

const HomeTabCardModal = ({
  title,
  children,
  onClickSubmitButton,
  resetDisable,
  show,
  displayToggle,
  onClickResetButton = noop,
  modalClassName = '',
  buttonType = 'button',
  secondaryButtonName = 'Reset all',
  primaryButtonName = 'Apply',
}) => (
  <Modal
    showClose
    displayToggle={displayToggle}
    show={show}
    modalClasses={`${styles.modal} ${styles.containerBordered__paddingNone} ${modalClassName}`}
  >
    <div className={`${styles.modalTitle}`}>
      {title}
    </div>
    <div className={`${styles.modalContent}`}>
      {children}
    </div>

    <div className={styles.modalDivider} />

    <div className={`${styles.modalFooter} flex flex-between`}>
      <Button
        classes={`${styles.modalButton} flex-shrink-0 t-600 btn btn-uppercased btn_bordered-link`}
        disabled={resetDisable}
        onClick={onClickResetButton}
      >
        {secondaryButtonName}
      </Button>
      <Button
        type={buttonType}
        classes={`${styles.modalButton} flex-shrink-0 t-600 btn btn-uppercased btn_primary`}
        onClick={onClickSubmitButton}
      >
        {primaryButtonName}
      </Button>
    </div>
  </Modal>
);

export default HomeTabCardModal;
