import { createInputsFromFields } from '../helpers/helper-methods';

const AccommodationsFields = {
  importanceOfPrice: 'Importance of Price',
  comfortAndService: 'Comfort & Service',
  atmosphereAndDesign: 'Atmosphere & Design',
  locationAndAccess: 'Location & Access',
  facilitiesAndActivities: 'Facilities & Activities',
  foodAndBeverages: 'Food & Beverages',
  environmentalAndSocial: 'Environmental & Social',
};

export const accommodationsFormInputs = createInputsFromFields(
  AccommodationsFields
);
export const accommodationsInitialValues = Object.keys(AccommodationsFields)
  .map((key) => ({ [key]: 3 }))
  .reduce((acc, currentItem) => ({ ...acc, ...currentItem }));
