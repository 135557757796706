import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import styles from './ValidationTooltip.module.scss';

const ValidationTooltip = ({ children, tooltipTextClassName = '', isShow }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });

  return (
    <>
     {isShow &&
        <div>
        <div
          className={styles.tooltipIcon}
          ref={setReferenceElement}
          style={popperStyles.referenceElement}
        />
          <div
          ref={setPopperElement}
          style={{
            ...popperStyles.popper,
            opacity: 1,
            zIndex: '2'
          }}
          {...attributes.popper}
          className={`${styles.tooltipText} ${tooltipTextClassName}`}
        >
          {children}
          <div ref={setArrowElement} style={popperStyles.arrow} className={styles.arrow} />
        </div>
      </div>
    }
    </>
  );
};

export default ValidationTooltip;
