import React, { Fragment, useState } from 'react';

import { Form, Formik } from 'formik';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import { objectToHash } from '../../helpers/helper-methods';
import { SEARCH_FILTERS_MODAL_INITIAL_VALUES } from '../../constans/search';

import styles from './OtherFiltersAccommodations.module.scss';

const OtherFiltersAccommodations = ({
  handleSubmit,
  inputs,
  // initialValues,
  formClassName = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueCount, setValueCount] = useState(0);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (values) => {
    let count = 0;

    Object.keys(SEARCH_FILTERS_MODAL_INITIAL_VALUES).forEach((key) => {
      if (
        objectToHash(SEARCH_FILTERS_MODAL_INITIAL_VALUES?.[key])
        !== objectToHash(values?.[key])
      ) {
        const arrDiff = SEARCH_FILTERS_MODAL_INITIAL_VALUES[key]
          .filter((item) => !values?.[key].includes(item))
          .concat(values?.[key]
            .filter((item) => !SEARCH_FILTERS_MODAL_INITIAL_VALUES[key].includes(item)));
        count += arrDiff.length;
      }
    });
    setValueCount(count);
  };

  const handleSubmitForm = (values) => {
    setBadgeCount(values);
    handleSubmit(values);
  };

  return (
    <>
      <Formik
        // enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={SEARCH_FILTERS_MODAL_INITIAL_VALUES}
        onSubmit={handleSubmitForm}
      >
        {({ resetForm, dirty }) => (
          <Form className={formClassName}>
            <HomeTabCardModal
              title="Other filters"
              show={isModalOpen}
              displayToggle={toggleModal}
              onClickResetButton={resetForm}
              onClickSubmitButton={toggleModal}
              resetDisable={!dirty}
              modalClassName={styles.modal}
              buttonType="submit"
            >
              {inputs.map(({ label, options, name }) => (
                <Fragment key={name}>
                  <div className={`${styles.sectionSubtitle} uppercased-text t-700`}>
                    {label}
                  </div>
                  <div className="flex flex-wrap">
                    <CheckboxGroup
                      name={name}
                      options={options}
                      valueField="value"
                      checkboxClassName={styles.checkbox}
                      wrapperClassName={`${styles.checkboxWrapper} col-12 col-md-4 col-sm-6`}
                      titleClassName={`${styles.checkboxText} ${styles.label}`}
                    />
                  </div>
                </Fragment>
              ))}
            </HomeTabCardModal>
          </Form>
        )}
      </Formik>
      <LandingFiltersButton onClick={toggleModal} badgeText={valueCount}>
        Other filters
      </LandingFiltersButton>
    </>
  );
};

export default OtherFiltersAccommodations;
