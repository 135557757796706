import React from 'react';

import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Button from '../UI/Button/Button';
import { Input } from '../UI/Input/Input';
import ArrayFieldErrorMessage from '../UI/ArrayFieldErrorMessage/ArrayFieldErrorMessage';
import MinusIcon from '../../assets/img/icons/ic_minus-redesigned.svg';
import PlusIcon from '../../assets/img/icons/ic_plus-redesigned.svg';

import styles from './index.module.scss';

const CounterInputBlockRedesigned = ({
  reduceDisabled,
  increaseDisabled,
  reduceValue,
  increaseValue,
  inputName,
  containerClassName,
}) => (
  <InputWrapper containerClasses={`mb-0 ${containerClassName}`}>
    <div className={styles.counter}>
      <Button
        disabled={reduceDisabled}
        classes={`${styles.button} btn t-600`}
        onClick={reduceValue}
      >
        <MinusIcon className={styles.icon} />
      </Button>
      <Input
        containerStyles={styles.inputWrapper}
        inputWrapperStyles={styles.input}
        hideError
        name={inputName}
        type="number"
      />
      <Button
        disabled={increaseDisabled}
        classes={`${styles.button} btn t-600`}
        onClick={increaseValue}
      >
        <PlusIcon className={styles.icon} />
      </Button>
    </div>
    <ArrayFieldErrorMessage name={inputName} />
  </InputWrapper>
);

CounterInputBlockRedesigned.defaultProps = {
  containerClasses: '',
  labelStyles: '',
  inputWrapperStyles: '',
};

export default CounterInputBlockRedesigned;
