import React from 'react';

import Spinner from '../UI/Spinner/Spinner';

import styles from './LandingTabSpinner.module.scss';

const LandingTabSpinner = ({ show }) => (
  <>
    {show && (
      <div className={`${styles.spinnerOuterWrapper}`}>
        <div className={`${styles.spinnerWrapper}`}>
          <Spinner spinnerClasses={styles.spinner} />
        </div>
      </div>
    )}
  </>
);

export default LandingTabSpinner;
