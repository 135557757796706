import React, { useEffect, useState } from 'react';
import { Field } from 'formik';

import HomeTabCard from '../Home/HomeTabCard/HomeTabCard';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Label from '../UI/Label/Label';
import { Input } from '../UI/Input/Input';
import FormikRadioGroup from '../RadioGroup/RadioGroup';
import ValidationTooltip from '../ValidationTooltip/ValidationTooltip';
import {
  BUDGET_PROPERTY,
  BudgetPeriodDisplay,
  // budgetFieldsInitialValues,
  MAX_BUDGET_PRICE,
  MIN_BUDGET_PRICE,
} from '../../constans/budget';
import { currenciesService } from '../../services/currencies.service';
import { integerPipeWithLimits } from '../../helpers/helper-methods';
import DropdownWithModal from '../DropdownWithModal';

import styles from './BudgetCard.module.scss';

function BudgetCard({
  divider,
  values,
  errors,
  enablePriceType = true,
  priceTypeOptions = BUDGET_PROPERTY,
  priceTypeDisplayOptions = BudgetPeriodDisplay,
  className = null,
  withTip = false,
  isMarketingPage = false,
  title = 'Budget',
}) {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    currenciesService().then((res) => {
      const currenciesFirstPart = [res[0], res[1]];
      const currenciesOtherPart = res.slice(2);
      const sortedOtherPart = currenciesOtherPart.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
      setCurrencies(currenciesFirstPart.concat(sortedOtherPart));
    });
  }, []);

  const displayPriceType = values?.priceType ? `/${priceTypeDisplayOptions[values?.priceType]}` : '';
  const displayValue = `${values.currency} ${values.minPrice}-${values.maxPrice}${displayPriceType}`;

  return (
    <HomeTabCard className={className} title={isMarketingPage ? 'Indicative budget' : title} divider={divider}>
      <ValidationTooltip isShow={errors?.maxPrice}>{errors?.maxPrice}</ValidationTooltip>
      <DropdownWithModal
        hideIcon
        darkValueColor
        errored={false}
        id="budget"
        placeHolder={values?.currency
          ? `${displayValue}`
          : 'Add your budget'}
        inputValues={values?.currency
          ? `${displayValue}`
          : ''}
        displayedValueClassName={styles.displayValue}
        dropdownClassName={styles.dropdown}
        containerClasses={styles.currencyInput}
        modalTitle="Budget"
      >
        <div className="flex-column">
          {withTip
              && <h2 className={styles.tipText}>Indicative budget</h2>}
          <div className="flex flex-wrap">
            <InputWrapper
              containerClasses={`col-3 pr-0 ${styles.budgetTypeInput}`}
            >
              <Label>{priceTypeOptions.currency.label}</Label>
              <CustomSelect
                hideError
                placeholder="$"
                name={priceTypeOptions.currency.name}
                options={currencies}
                containerStyles={styles.inputContainer}
              />
            </InputWrapper>
            <div
              className={`flex col-md-8 col-xs-12 pl-0 pr-0 ${styles.priceFilterWrapper}`}
            >
              <InputWrapper
                containerClasses={`col-4 ${styles.budgetInput}`}
              >
                <Label>{priceTypeOptions.minPrice.label}</Label>
                <Input
                  formater={(price) => integerPipeWithLimits({
                    value: price,
                    minValue: MIN_BUDGET_PRICE,
                    maxValue: MAX_BUDGET_PRICE,
                  })}
                  type="text"
                  name={priceTypeOptions.minPrice.name}
                />
              </InputWrapper>
              <div
                className={` ${styles.priceFilterWrapperDelimiter}`}
              />
              <InputWrapper
                containerClasses={`col-3 ${styles.budgetInput}`}
              >
                <Label>{priceTypeOptions.maxPrice.label}</Label>
                <Input
                  formater={(price) => integerPipeWithLimits({
                    value: price,
                    minValue: MIN_BUDGET_PRICE,
                    maxValue: MAX_BUDGET_PRICE,
                  })}
                  type="text"
                  name={priceTypeOptions.maxPrice.name}
                />
              </InputWrapper>
            </div>
          </div>
          {enablePriceType && (
          <div className={`${styles.periodContainer} col-12`}>
            <Field
              name={priceTypeOptions.priceType.name}
              defaultValue={priceTypeOptions.priceType.initialValue}
              options={priceTypeOptions.priceType.values}
              component={FormikRadioGroup}
              wrapperStyles={styles.periodInput}
            />
          </div>
          )}
        </div>
      </DropdownWithModal>
    </HomeTabCard>
  );
}

export default BudgetCard;
