import React from 'react';

import styles from './HomeTabCard.module.scss';

const HomeTabCard = ({
  title,
  divider,
  titleClassName,
  contentClassName,
  children,
  className,
  disabled = false,
}) => (
  <div className={`${styles.card} ${className} ${disabled ? styles.disabled : ''}`}>
    <div className={`${divider ? styles.cardDivider : ''}`} />
    <div className={`${contentClassName}`}>
      <div className={`${styles.cardTitle} ${titleClassName}`}>
        {title}
      </div>
      <div>
        {children}
      </div>
    </div>
  </div>
);

export default HomeTabCard;
