import React from 'react';

import styles from './HomeTabSection.module.scss';

const HomeTabSection = ({ children, className }) => (
  <div className={`${styles.section} ${className}`}>
    {children}
  </div>
);

export default HomeTabSection;
