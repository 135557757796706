import http, { createInstance } from './http.service';

const itinerarySearchService = () => {
  const basePath = '/search/itineraries';
  const uuidSearchHttpService = createInstance({
    baseURL: process.env.API_URL,
    useToast: false,
  });

  const getSearchUUID = (model) => http.post(`${basePath}/search`, model);
  const searchByUUID = (model) => uuidSearchHttpService.get(`${basePath}/search`, { params: model });
  const generatePdfPack = (model, body) => http.post(`${basePath}/pdf`, body, { params: { ...model } });

  return {
    getSearchUUID,
    searchByUUID,
    generatePdfPack
  };
};

export default itinerarySearchService;
