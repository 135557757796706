import { date, object, string } from 'yup';
import moment from 'moment/moment';
import { DATE_REQUIRED, DESTINATION_REQUIRED } from '../constans/error-messages';
import { DEFAULT_FORMAT } from '../constans/formats';
import { budgetSchema, searchAgeOfKidsValidationSchema } from '../constans/validation-schemas';

export const validationSchemasForStay = object().shape({
  checkin: date().required(DATE_REQUIRED),
  checkout: date()
    .test({
      name: 'MinEndDate',
      test(value) {
        return this.parent.checkin >= value ? this.createError({
          message: `Check out date must be late than ${moment(this.parent.checkin).format(DEFAULT_FORMAT)}`,
          path: 'checkout',
        }) : true;
      },
    }),
  locationName: string().required(DESTINATION_REQUIRED),
}).concat(searchAgeOfKidsValidationSchema)
  .concat(budgetSchema);
