import React from 'react';

import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import styles from './index.module.scss';

const SearchModalRedesigned = ({
  title, displayToggle, show, children,
}) => (
  <Modal
    displayToggle={displayToggle}
    modalClasses={styles.modal}
    show={show}
  >
    <div className={styles.modalTitle}>
      {title}
    </div>
    {children}
    <Button
      onClick={displayToggle}
      classes={`${styles.modalButton} flex-shrink-0 t-600 btn btn_common btn-uppercased btn_primary`}
    >
      apply
    </Button>
  </Modal>
);

export default SearchModalRedesigned;
