import React from 'react';
import DestinationsService from '../../services/destinationsService';
import ExploreDestinationLinkButton from '../ExploreDestinationLinkButton/ExploreDestinationLinkButton';
import { noop } from '../../helpers/helper-methods';

import styles from './ExploreDestinationCard.module.scss';
import { generatLinkPath } from '../../helpers/generateLinks';

const destinationsService = DestinationsService();

function ExploreDestinationCard({
  destination,
  activities,
  properties,
  categoriesFilters = [],
  setTab = noop,
  storageDestination,
}) {
  let link = '';

  if (storageDestination?.country) {
    link = generatLinkPath(storageDestination);
  }

  return (
    <div className={`${styles.buttonContainer} t-600`}>
      {destination?.locationName && (
        <div className={styles.block}>
          <h4 className={styles.text}>
            For{' '}
            <a
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              className={`${!destination?.locationName} btn btn_primary-link-light`}
            >
              {destination?.locationName}
            </a>{' '}
            we have
          </h4>

          <div className="flex">
            {activities && (
              <ExploreDestinationLinkButton
                onClick={setTab}
                buttonText="Activities"
                destination={destination}
                serviceGet={destinationsService.getDestinationActivities}
                redirectUrl="/activities/results/?"
                categoriesFilters={categoriesFilters}
              />
            )}
            {activities && properties && (
              <h4 className={`${styles.text} ${styles.ml8}`}>&</h4>
            )}
            {properties && (
              <ExploreDestinationLinkButton
                onClick={setTab}
                buttonText="Properties"
                destination={destination}
                serviceGet={destinationsService.getDestinationProperties}
                redirectUrl="/properties/results/?"
                categoriesFilters={categoriesFilters}
                isActivities={false && !activities}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExploreDestinationCard;
