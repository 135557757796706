import slugify from 'slugify';

export const LOCATION_VIEW = {
  COUNTRY: 'Countries',
  REGION: 'Regions',
  CITY: 'Cities',
  DISTRICT: 'Districts',
};

export const cleanedUrl = (text) => {
  const url = text.replace(/&/g, '');
  return slugify(url, {
    replacement: '-',
    remove: /[*+~.,()'"!:@]/g,
  });
};
