import React from 'react';

import CounterInputBlock from '../UI/CounterInputBlock/CounterInputBlock';
import DropdownWithModal from '../DropdownWithModal';

import styles from './LandingRooms.module.scss';

function LandingRooms({
  values,
  setFieldValue,
  changeMathValue,
}) {
  const switchValue = (key) => {
    if (key === selectedValue) {
      setSelectedValue(null);
    } else {
      setSelectedValue(key);
    }
  };

  const singularlyOrPlural = values?.rooms > 1 ? 'rooms' : 'room';
  const optimized = values.optimized ? '(Optimize)' : '';
  const maximized = values.maximized ? '(Maximize)' : '';

  const inputValues = values?.rooms > 0 ? `${values?.rooms} ${singularlyOrPlural} ${optimized}${maximized} ` : '';

  return (
    <DropdownWithModal
      hideIcon
      darkValueColor
      errored={false}
      id="rooms"
      placeHolder="Select # of rooms"
      inputValues={inputValues}
      displayedValueClassName={styles.displayedValue}
      dropdownClassName={styles.dropdown}
      containerClasses={styles.roomsInput}
      modalTitle="Select # of rooms"
    >
      <CounterInputBlock
        className={`text-center pe-none ${styles.darkText}`}
        increaseValue={() => changeMathValue('rooms', Number(values.rooms), 'up')}
        reduceValue={() => changeMathValue('rooms', Number(values.rooms))}
        reduceDisabled={!values?.rooms}
        buttonsClassName="flex flex-column flex-center"
        inputName="rooms"
      />
    </DropdownWithModal>
  );
}

export default LandingRooms;
