import React, { useState } from 'react';

import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';
import InputDropDowned from '../UI/InputDropDowned/InputDropDowned';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import SearchModalRedesigned from '../SearchModalRedesigned';

import styles from './index.module.scss';
import { noop } from '../../helpers/helper-methods';

function DropdownWithModal({
  id,
  children,
  inputValues,
  placeHolder,
  displayedValueClassName,
  dropdownClassName,
  containerClasses,
  modalTitle = 'Add travellers',
  setFieldTouched = noop,
  names = [],
}) {
  const [isShown, setIsShown] = useState(false);
  const width = useWindowWidth();
  const displayValue = inputValues?.trim();

  return width < PHONE_WIDTH_BREAKPOINT
    ? (
      <>
        <div className={`${styles.displayValue} ${displayedValueClassName} ${!displayValue?.length && styles.displayValuePlaceholder} `} onClick={() => setIsShown(true)}>
          {displayValue || placeHolder}
        </div>
        <SearchModalRedesigned
          title={modalTitle}
          displayToggle={() => setIsShown(false)}
          modalClasses={styles.modal}
          show={isShown}
        >
          {children}
        </SearchModalRedesigned>
      </>
    )
    : (
      <InputDropDowned
        hideIcon
        darkValueColor
        errored={false}
        id={id}
        placeHolder={placeHolder}
        inputValues={inputValues}
        displayedValueClassName={`${styles.displayValue} ${displayedValueClassName}`}
        dropdownClassName={dropdownClassName}
        activeStylesOnOpen={false}
        containerClasses={containerClasses}
        setFieldTouched={setFieldTouched}
        names={names}
      >
        {children}
      </InputDropDowned>
    );
}

export default DropdownWithModal;
