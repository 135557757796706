import React, { useEffect, useState } from 'react';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import { CheckboxWithoutBinding } from '../UI/Checkbox/Checkbox';

import styles from './Lounge.module.scss';

const Lounge = ({
  initialValues,
  inputs,
  values,
  setFieldValue,
  headerTooltip,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueCount, setValueCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (formValues) => {
    let count = 0;
    Object.keys(initialValues).forEach((key) => {
      if (Array.isArray(initialValues[key])) {
        if (JSON.stringify(initialValues[key]) !== JSON.stringify(formValues[key])) {
          count += Math.abs(initialValues[key].length - formValues[key].length);
        }
      } else if (initialValues[key] !== formValues[key]) {
        count += 1;
      }
    });
    setValueCount(count);
    setIsDirty(count > 0);
  };

  const onResetButtonClick = () => {
    Object.keys(initialValues).forEach((key) => {
      setFieldValue(key, initialValues[key]);
    });
    setIsDirty(false);
  };

  const onSubmitButtonClick = (fieldValues) => {
    setBadgeCount(fieldValues);
    toggleModal();
  };

  useEffect(() => {
    setBadgeCount(values);
  }, [values]);

  return (
    <>
      <HomeTabCardModal
        title={(
          <div className="flex flex-row flex-v-center">
            Executive lounge
            <InfoTooltip>
              {headerTooltip}
            </InfoTooltip>
          </div>
              )}
        show={isModalOpen}
        displayToggle={toggleModal}
        onClickResetButton={onResetButtonClick}
        onClickSubmitButton={() => onSubmitButtonClick(values)}
        resetDisable={!isDirty}
      >
        <>
          <div className={`${styles.sectionSubtitle} flex flex-row uppercased-text`}>
            Free breakfast in lounge
          </div>
          <div className={`${styles.checkboxesContainer}`}>
            {inputs.map(({ label, value }, index) => (
              <CheckboxWithoutBinding
                key={index}
                containerClasses="c-custom"
                checkboxClasses={`
                      ${styles.checkbox}
                        c-custom__check-bordered-primary
                        c-custom__check-sm
                        c-custom__custom-input
                        c-custom__check
                      `}
                labelClasses={`
                        ${styles.checkboxSelectAll}
                        ${styles.mb20}
                        flex flex-v-center col-12 col-sm-6
                      `}
                isChecked={value === values.freeBreakfastInLounge}
                value={value}
                onChange={(e) => setFieldValue('freeBreakfastInLounge', e.target.value)}
              >
                <div className={`
                        ${styles.checkboxText}
                        ${styles.label}
                        flex flex-v-center
                      `}
                >
                  {' '}
                  {label}
                  {' '}
                </div>
              </CheckboxWithoutBinding>
            ))}
          </div>
        </>
        <>
          <div className={`${styles.sectionSubtitle} uppercased-text`}>
            Alcoholic drinks in lounge
          </div>
          <div className={styles.checkboxesContainer}>
            {inputs.map(({ label, value }, index) => (
              <CheckboxWithoutBinding
                key={index}
                containerClasses="c-custom"
                checkboxClasses={`
                      ${styles.checkbox}
                        c-custom__check-bordered-primary
                        c-custom__check-sm
                        c-custom__custom-input
                        c-custom__check
                      `}
                labelClasses={`
                        ${styles.checkboxSelectAll}
                        ${styles.mb20}
                        flex flex-v-center col-12 col-sm-6
                      `}
                isChecked={value === values.alcoholicDrinksInLounge}
                value={value}
                onChange={(e) => setFieldValue('alcoholicDrinksInLounge', e.target.value)}
              >
                <div className={`
                        ${styles.checkboxText}
                        ${styles.label}
                        flex flex-v-center
                      `}
                >
                  {' '}
                  {label}
                  {' '}
                </div>
              </CheckboxWithoutBinding>
            ))}
          </div>
        </>
        <div>
          <div className={`${styles.sectionSubtitle} uppercased-text`}>
            Food, non-alcoholic drinks and other lounge perks:
          </div>
          <div className={styles.checkboxesContainer}>
            {inputs.map(({ label, value }, index) => (
              <CheckboxWithoutBinding
                key={index}
                containerClasses="c-custom"
                checkboxClasses={`
                      ${styles.checkbox}
                        c-custom__check-bordered-primary
                        c-custom__check-sm
                        c-custom__custom-input
                        c-custom__check
                      `}
                labelClasses={`
                        ${styles.checkboxSelectAll}
                        ${styles.mb20}
                        flex flex-v-center col-12 col-sm-6
                      `}
                isChecked={value === values.foodAndNonAlcoholicDrinks}
                value={value}
                onChange={(e) => setFieldValue('foodAndNonAlcoholicDrinks', e.target.value)}
              >
                <div className={`
                        ${styles.checkboxText}
                        ${styles.label}
                        flex flex-v-center
                      `}
                >
                  {' '}
                  {label}
                  {' '}
                </div>
              </CheckboxWithoutBinding>
            ))}
          </div>
        </div>
      </HomeTabCardModal>
      <LandingFiltersButton onClick={toggleModal} badgeText={valueCount}>
        Lounge
      </LandingFiltersButton>
    </>
  );
};

export default Lounge;
