import { createCheckboxOptionsFromObject } from '../helpers/helper-methods';

export const PropertyCategoriesEnum = {
  1: 'Luxury hotel',
  2: 'Upscale hotel',
  3: 'Mid - range & Upper mid - range hotel',
  4: 'Economy hotels & Guesthouses',
  5: 'Residence apt - upscale',
  6: 'Residence apt - standard',
  7: 'Villas',
  8: 'Private houses',
  9: 'Private apartments',
  10: 'Private rooms',
  11: 'Camping & mobile home',
  12: 'Bed & Breakfast',
};
export const PropertyCategoriesForRemoving = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const PropertyCategories = {
  C1_LUXURY_HOTEL: 'Luxury hotel',
  C2_UPSCALE_HOTEL: 'Upscale & Upper-mid range hotels',
  C3_MID_RANGE_AND_UPPER_MID_RANGE_HOTEL: 'Mid-range hotels',
  C4_ECONOMY_HOTEL_AND_GUESTHOUSES: 'Economy hotels & Guesthouses',
  C5_RESIDENCE_ART_UPSCALE: 'Hostels',
  C6_RESIDENCE_ART_STANDARD: 'Bed & Breakfast',
  C7_VILLAS: 'Serviced apartments',
  C8_PRIVATE_HOUSES: 'Villas',
  C9_PRIVATE_APARTMENTS: 'Houses',
  C10_PRIVATE_ROOMS: 'Apartments',
  C11_CAMPING_AND_MOBILE_HOME: 'Private rooms',
  C12_BED_AND_BREAKFAST: 'Camping & mobile home',
};

export const PROPERTY_CATEGORY_OPTIONS = createCheckboxOptionsFromObject(PropertyCategories);
export const PROPERTY_CATEGORY_FILTER_INITIAL_VALUES = Object
  .keys(PropertyCategories)
  .map((key) => ({ [key]: false }))
  .reduce((acc, currentValue) => ({ ...acc, ...currentValue }));
