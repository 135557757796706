import React from 'react';

import Button from '../../UI/Button/Button';
import { noop } from '../../../helpers/helper-methods';

import styles from './LandingFiltersButton.module.scss';

const LandingFiltersButton = ({
  children,
  badgeText,
  buttonClassNames = '',
  badgeClassNames = '',
  type = 'button',
  onClick = noop,
}) => (
  <Button
    type={type}
    classes={`${styles.filtersButton} t-600 btn ${buttonClassNames}`}
    onClick={onClick}
  >
    {children}
    {
      badgeText ? (
        <div className={`${styles.filtersButtonBadge} t-500 ${badgeClassNames}`}>
          {badgeText}
        </div>
      ) : ''
    }
  </Button>
);

export default LandingFiltersButton;
