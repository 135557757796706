import { PROFILE_KEYS } from '../constans/search';

export const createSearchProfile = (searchProfile) => {
  const userProfile = {};

  Object
    .entries(searchProfile)
    .forEach(([key, value]) => {
      if (PROFILE_KEYS.includes(key)) {
        userProfile[key] = value;
      }
    });

  return userProfile;
};
