import React, { useMemo, useCallback, useEffect } from 'react';

import moment from 'moment';
import { RESULTS_FORMAT, SEARCH_REQUEST_FORMAT } from '../../constans/formats';
import Calendar from '../Calendar/Calendar';
import DropdownWithModal from '../DropdownWithModal';
import ValidationTooltip from '../ValidationTooltip/ValidationTooltip';
import { noop } from '../../helpers/helper-methods';
import StorageService from '../../services/storage.service';
import { STORAGE_DESTINATION } from '../DestinationTree';
import { DESTINATION_TYPE } from '../DestinationTree/const';

import styles from './DateInputRedesigned.module.scss';

const COUNT_OF_DAYS = {
  DISTRICT: 2,
  CITY: {
    STANDARD: 5,
    LARGE: 10,
  },
  REGION: 15,
};

const storageService = StorageService();

function DateInputRedesigned({
  values,
  setFieldValue,
  calendarFields,
  children,
  errors,
  setIsDateError = noop,
  touched,
  setFieldTouched = noop,
  saveToStore = false,
  withDateRangeValidation = true,
  untilTommorow = false,
}) {
  const {
    checkin,
    checkout,
    flexPeriod,
    regionId,
    cityId,
    districtId,
  } = values;
  const destination = storageService.get(STORAGE_DESTINATION);

  const displayValues = (start, end, flex) => {
    const startDate = moment(start).format(RESULTS_FORMAT);
    const endDate = moment(end).format(RESULTS_FORMAT);

    return `${startDate} - ${endDate} ${flex ? `+-${flex}days` : ''}`;
  };

  const createInput = useMemo(
    () => (
      <Calendar
        setFieldValue={setFieldValue}
        fields={calendarFields}
        checkin={checkin}
        checkout={checkout}
        saveToStore={saveToStore}
        untilTommorow={untilTommorow}
      >
        {children}
      </Calendar>
    ),
    [checkin, checkout, setFieldValue, calendarFields, children]
  );

  const getCityDateLimit = () => {
    const districtList = storageService.get(DESTINATION_TYPE.district, []);
    const cityDaysCount = destination?.city?.isLarge
      ? COUNT_OF_DAYS.CITY.LARGE
      : COUNT_OF_DAYS.CITY.STANDARD;

    if (!districtList.length) {
      return cityDaysCount;
    }
    return Math.min(
      districtList.length * COUNT_OF_DAYS.DISTRICT,
      cityDaysCount
    );
  };

  const getRegionDateLimit = () => {
    const citiesList = storageService.get(DESTINATION_TYPE.city, []);
    if (!citiesList.length) return COUNT_OF_DAYS.REGION;
    const standartCities = citiesList.filter(({ isLarge }) => !isLarge);
    const largeCities = citiesList.filter(({ isLarge }) => isLarge);
    return Math.min(
      standartCities.length * COUNT_OF_DAYS.CITY.STANDARD +
        largeCities.length * COUNT_OF_DAYS.CITY.LARGE,
      COUNT_OF_DAYS.REGION
    );
  };

  const getDateRangeCap = useCallback(() => {
    if (districtId || destination?.district?.id) return COUNT_OF_DAYS.DISTRICT;
    if (cityId || destination?.city?.id) {
      return getCityDateLimit();
    }
    return getRegionDateLimit();
  }, [
    destination,
    regionId,
    cityId,
    districtId,
    storageService,
    getCityDateLimit,
    getRegionDateLimit,
  ]);

  const dateRangeCap = useMemo(() => getDateRangeCap(), [values]);
  const dateRangeCapError = `Max ${dateRangeCap} days for this destination`;

  const diffDays = useMemo(() => {
    if (checkin && checkout) {
      const start = moment(checkin, SEARCH_REQUEST_FORMAT);
      const end = moment(checkout, SEARCH_REQUEST_FORMAT);
      return moment.duration(end.diff(start)).asDays();
    }

    return 0;
  }, [checkin, checkout]);

  const isShowRangeCapError = diffDays >= dateRangeCap;

  useEffect(() => {
    if (withDateRangeValidation) {
      setIsDateError(isShowRangeCapError);
    }
  }, [isShowRangeCapError]);
  return (
    <>
      <ValidationTooltip
        isShow={isShowRangeCapError && withDateRangeValidation}
      >
        {dateRangeCapError}
      </ValidationTooltip>
      <ValidationTooltip isShow={errors?.checkin && touched?.checkin}>
        {errors?.checkin}
      </ValidationTooltip>
      <DropdownWithModal
        hideIcon
        darkValueColor
        errored={errors?.checkin || errors?.checkout}
        id="datepicker"
        placeHolder="Add dates"
        inputValues={
          checkin && checkout
            ? displayValues(checkin, checkout, flexPeriod)
            : ''
        }
        displayedValueClassName={styles.displayValue}
        dropdownClassName={`${styles.dropdown}`}
        containerClasses={styles.dateInput}
        modalTitle="Start & End Dates"
        setFieldTouched={setFieldTouched}
        names={['checkin']}
      >
        {createInput}
      </DropdownWithModal>
    </>
  );
}

export default DateInputRedesigned;
