import React, { useEffect, useState } from 'react';
import { isObject } from 'lodash';
import StorageService from '../../services/storage.service';
import ValidationTooltip from '../ValidationTooltip/ValidationTooltip';

import DestinationTree, { STORAGE_DESTINATION } from '../DestinationTree';
import Button from '../UI/Button/Button';

import styles from './SelectDestinationRedesign.module.scss';

function SelectDestinationRedesign({
  setFieldValue,
  setFieldTouched = () => {},
  error = '',
  touched = false,
  onSelect,
  initialValue,
  isRegionRequired,
  buttonClasses = 'flex-shrink-0 t-600 btn btn_primary-link-light',
  selectedDestinationButtonClasses = `flex-shrink-0 t-600 btn p-0 ml-1 ${styles.button}`,
  classes = 'flex flex-v-center',
  destinationModalClasses,
}) {
  const [selectedDestination, setSelectedDestination] = useState(initialValue);
  const [isDestinationTreeShown, setIsDestinationTreeShown] = useState(false);

  const toggleModal = () => {
    setIsDestinationTreeShown((prev) => !prev);
    setFieldTouched('locationName', isDestinationTreeShown);
  };

  const handleSelect = ({
    name, country, region, city, district, locationName,
  }) => {
    setFieldValue('locationName', name || locationName);
    setFieldValue('countryId', country?.id);
    setFieldValue('regionId', region?.id);
    setFieldValue('cityId', city?.id);
    setFieldValue('districtId', district?.id);
    onSelect({
      locationName: name || locationName,
      countryId: country?.id || initialValue?.country,
      regionId: region?.id || initialValue?.region,
      cityId: city?.id || (initialValue?.city.id ? initialValue?.city.id
        : (isObject(initialValue?.city) ? null : initialValue?.city)),
      districtId: district?.id || (initialValue?.district.id ? initialValue?.district.id
        : (isObject(initialValue?.district) ? null : initialValue?.district)),
    });
  };

  useEffect(() => {
    if (selectedDestination) {
      handleSelect(selectedDestination);
    }
  }, [selectedDestination]);

  useEffect(() => {
    if (initialValue) {
      setSelectedDestination(initialValue);
    } else if (StorageService().get(STORAGE_DESTINATION)?.country) {
      setSelectedDestination(StorageService().get(STORAGE_DESTINATION));
    }
  }, []);

  return (
    <div className={classes}>
      <ValidationTooltip isShow={error && touched}>{error}</ValidationTooltip>
      <DestinationTree
        withSelect
        isRegionRequired={isRegionRequired}
        selected={selectedDestination || StorageService().get(STORAGE_DESTINATION)}
        isOpen={isDestinationTreeShown}
        onSelect={setSelectedDestination}
        onCloseClick={toggleModal}
        destinationModalClasses={destinationModalClasses}
      />
      <Button type="button" classes={selectedDestinationButtonClasses} onClick={toggleModal}>
        {selectedDestination?.name
          ? selectedDestination?.name
          : <span className={buttonClasses}> Select destination </span> }
      </Button>
    </div>
  );
}

export default SelectDestinationRedesign;
