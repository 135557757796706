import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import { ImportanceTitle } from '../Users/SearchProfile/SearchProfile';
import StorageService from '../../services/storage.service';
import Slider from '../Slider';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';

import styles from './Priorities.module.scss';

const Priorities = ({
  handleSubmit,
  inputs,
  initialValues,
  formClassName = '',
  displayComfortAndServiceTooltip = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueCount, setValueCount] = useState(0);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (values) => {
    let count = 0;
    Object.keys(initialValues).forEach((key) => {
      if (initialValues[key] !== values[key]) {
        count += 1;
      }
    });
    setValueCount(count);
  };

  const getProfile = () => {
    const { searchProfile } = StorageService()
      .get(LOCALSTORAGE_KEYS.USER, {});

    return { ...initialValues, ...searchProfile };
  };

  const handleSubmitForm = (values) => {
    setBadgeCount(values);
    handleSubmit(values);
  };

  useEffect(() => {
    const profile = getProfile();
    setBadgeCount(profile);
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getProfile()}
        onSubmit={handleSubmitForm}
      >
        {({ resetForm, dirty }) => (
          <Form className={formClassName}>
            <HomeTabCardModal
              title="Priorities"
              show={isModalOpen}
              displayToggle={toggleModal}
              onClickResetButton={resetForm}
              onClickSubmitButton={toggleModal}
              resetDisable={!dirty}
              buttonType="submit"
            >
              <section className="pt-0">
                <ImportanceTitle />
                {inputs.map(({ name, label }) => (
                  <Slider
                    name={name}
                    label={(name === 'comfortAndService' && displayComfortAndServiceTooltip)
                      ? (
                        <div className="flex">
                          {label}
                          <InfoTooltip>Relates to accommodation, if any</InfoTooltip>
                        </div>
                      )
                      : label}
                    key={name}
                    wrapperClassName={`row flex-v-center flex-between ${styles.rangeWrapper}`}
                    labelClassName="col-md-6"
                    sliderClassName="col-md-6"
                  />
                ))}
              </section>
            </HomeTabCardModal>
          </Form>
        )}
      </Formik>
      <LandingFiltersButton onClick={toggleModal} badgeText={valueCount}>
        Priorities
      </LandingFiltersButton>
    </>
  );
};

export default Priorities;
