import React, { useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import qs from 'qs';
import { navigate } from 'gatsby';
import StorageService from '../../../services/storage.service';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_PRICE_KEYS,
} from '../../../constans/localstorage';
import HomeTabSection from '../HomeTabSection/HomeTabSection';
import HomeTabCard from '../HomeTabCard/HomeTabCard';
import ExploreDestinationCard from '../../ExploreDestination/ExploreDestinationCard';
import SelectDestinationRedesign from '../../SelectDestinationRedesign/SelectDestinationRedesign';
import AdjustPropertyType from '../../AdjustPropertyType/AdjustPropertyType';
import Priorities from '../../Priorities/Priorities';
import Button from '../../UI/Button/Button';
import {
  accommodationsFormInputs,
  accommodationsInitialValues,
} from '../../../constans/accommodations';
import OtherFiltersAccommodations from '../../OtherFiltersAccommodations/OtherFiltersAccommodations';
import {
  EXECUTIVE_LOUNGE_INFO_TOOLTIP,
  FILTERS_MODAL_FIELDS,
  LOUNGE_SECTION_OPTIONS,
  SEARCH_PAGE_INITIAL_VALUES,
} from '../../../constans/search';

import Lounge from '../../Lounge/Lounge';
import GuestsInputRedesigned from '../../GuestsInputRedesigned';
import BudgetCard from '../../Budget/BudgetCard';
import SearchService from '../../../services/search.service';
import {
  BUDGET_PROPERTY_STAY,
  budgetFieldsStayInitialValues,
  BudgetPeriodStayDisplay,
  getPriceRangeFromURL,
} from '../../../constans/budget';
import DateInputRedesigned from '../../DateInputRedesigned/DateInputRedesigned';
import { searchProfileDefaultInstance } from '../../../constans/form-fields';
import LandingRooms from '../../LandingRooms/LandingRooms';
import LandingTabSpinner from '../../LandingTabSpinner/LandingTabSpinner';

import styles from './StaysTab.module.scss';
import { STORAGE_DESTINATION } from '../../DestinationTree';
import { createSearchProfile } from '../../../helpers/createSearchProfile';
import { validationSchemasForStay } from '../../../helpers/validationSchemasForStay';
import { getFullLocationName } from '../../../helpers/generateLinks';

function StaysTab({
  handleSubmitForm,
  setTab,
  initValues,
  isMarketingPage = false,
  isModal = false,
  destinationModalClasses,
  cardClass,
  withInfo = false,
}) {
  const storageDestination = StorageService().get(STORAGE_DESTINATION);
  const [isSearching, setIsSearching] = useState(false);
  const [isDateError, setIsDateError] = useState(false);

  const setIsSearchingDebounced = (state) =>
    setTimeout(() => setIsSearching(state), 300);

  const storage = StorageService();

  const searchData = useMemo(
    () => StorageService().get(LOCALSTORAGE_KEYS.SEARCH),
    [storage]
  );

  const searchDates = useMemo(
    () => StorageService().get(LOCALSTORAGE_KEYS.SEARCH_DATES),
    [storage]
  );

  const minPrice = storage.get(LOCALSTORAGE_PRICE_KEYS.MIN_PRICE, null);
  const maxPrice = storage.get(LOCALSTORAGE_PRICE_KEYS.MAX_PRICE, null);

  const formInitData = useMemo(
    () => ({
      name: initValues?.locationName || '',
      country: initValues?.countryId || 0,
      region: initValues?.regionId || 0,
      city: initValues?.cityId || 0,
      district: initValues?.districtId || 0,
      adults: ['', ''],
      ...SEARCH_PAGE_INITIAL_VALUES,
      ...budgetFieldsStayInitialValues,
      ...searchData.values,
      countryId: initValues?.countryId || null,
      regionId: initValues?.regionId || null,
      cityId: initValues?.cityId || null,
      districtId: initValues?.districtId || null,
      locationName: initValues?.locationName || '',
      //  kidGuests: initValues?.kids || 0,
      // rooms: initValues?.rooms || 1,
      checkin: isMarketingPage
        ? initValues?.checkin || searchDates?.checkin || ''
        : searchDates?.checkin || initValues?.checkin || '',
      checkout: isMarketingPage
        ? initValues?.checkout || searchDates?.checkout || ''
        : searchDates?.checkout || initValues?.checkout || '',
      ...getPriceRangeFromURL(initValues, minPrice, maxPrice),
    }),
    []
  );

  const [filters, setFilters] = useState(searchData.filters || {});
  const searchService = useMemo(() => SearchService(), []);
  const handleFiltersSubmit = (values) => {
    setFilters(values);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let userProfile = {};
    setIsSearchingDebounced(true);
    setSubmitting(true);

    const user = storage.get(LOCALSTORAGE_KEYS.USER, {});
    const profilingResults = storage.get(LOCALSTORAGE_KEYS.PROFILING, null);

    // User filled profiling form
    if (profilingResults) {
      userProfile = createSearchProfile(profilingResults);
    }

    // Authorized user doesn't filled profile form
    if (user?.searchProfile && !profilingResults) {
      userProfile = createSearchProfile(user.searchProfile);
    }

    // UnAuthorized user doesn't filled profile form
    if (!user?.searchProfile && !profilingResults) {
      userProfile = createSearchProfile(searchProfileDefaultInstance);
    }

    const { locationName, ...requestValues } = values;

    const {
      data: { uuid },
    } = await searchService.createSearchUUID({ ...requestValues, userProfile });

    const processedFilters = {};
    const {
      adultGuests,
      kidGuests,
      requiredOptions,
      checkin,
      checkout,
      kids,
      currency,
      rooms,
    } = values;

    Object.values(filters)
      .flat()
      .forEach((filter) => {
        processedFilters[filter] = true;
      });

    // ! once upon a time it wont fit url string limit
    const query = qs.stringify({
      requestId: uuid,
      adultGuests,
      kidGuests,
      requiredOptions,
      checkin,
      checkout,
      kids,
      currency,
      numberOfRooms: rooms,
      searchLocation: locationName,
      ...processedFilters,
    });

    storage.set(LOCALSTORAGE_KEYS.SEARCH, {
      values,
      filters,
    });

    const propertiesModel = {
      uuid,
      location: locationName,
      countryId: storageDestination?.country?.id || initValues?.countryId,
      offset: 0,
      limit: 0,
      ...processedFilters,
    };

    if (storageDestination?.region?.id || initValues?.regionId) {
      propertiesModel.regionId =
        storageDestination?.region?.id || initValues.regionId;
    }
    if (storageDestination?.city?.id || initValues?.cityId) {
      propertiesModel.cityId =
        storageDestination?.city?.id || initValues.cityId;
    }
    if (storageDestination?.district?.id || initValues?.districtId) {
      propertiesModel.districtId =
        storageDestination?.district?.id || initValues.districtId;
    }

    const { pagination } = await searchService.getPropertiesByUUID(
      propertiesModel
    );
    setSubmitting(false);

    if (pagination.totalCount > 0) {
      storage.remove(LOCALSTORAGE_KEYS.PROFILING);

      navigate(`/results?${query}`);
      return;
    }

    const nothingFoundQuery = qs.stringify({
      requestId: uuid,
    });

    navigate(`/nothing-found?${nothingFoundQuery}`);
  };

  const [destination, setDestination] = useState({});
  const handleSearch = (values, formikHelpers) => {
    handleSubmit(values, formikHelpers)
      // .then(() => setIsSearchingDebounced(false)) //fixme: it causes a memory leak
      .catch(() => setIsSearchingDebounced(false));
  };

  const onClickFindTheBest = () => {
    setTab();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onClickFindTheBestButton',
    });
  };

  return (
    <>
      <LandingTabSpinner show={isSearching} />
      <Formik
        enableReinitialize
        validateOnBlur
        validateOnChange
        initialValues={{
          ...formInitData,
          locationName: isMarketingPage
            ? formInitData.name
            : storageDestination?.country?.name
            ? getFullLocationName(storageDestination)
            : '',
        }}
        initialTouched={{
          [BUDGET_PROPERTY_STAY.maxPrice.name]: true,
          [BUDGET_PROPERTY_STAY.minPrice.name]: true,
        }}
        validationSchema={validationSchemasForStay}
        onSubmit={handleSearch}
      >
        {({
          setFieldValue,
          values,
          errors,
          initialValues,
          isValid,
          setFieldTouched,
          touched,
        }) => {
          const changeMathValue = (field, value, direction) => {
            setFieldValue(field, direction ? value + 1 : value - 1);
          };
          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MIN_PRICE,
            values.minPrice
          );
          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MAX_PRICE,
            values.maxPrice
          );

          return (
            <Form>
              <div
                className={
                  isMarketingPage ? styles.marketingTab : styles.staysSection
                }
              >
                <HomeTabSection className={styles.marketingTabSection}>
                  {!isMarketingPage && (
                    <HomeTabCard title="Destination" className={cardClass}>
                      <SelectDestinationRedesign
                        onSelect={setDestination}
                        classes={`${styles.wrapper}`}
                        isRegionRequired
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValue={
                          initialValues?.country
                            ? {
                                locationName:
                                  initialValues?.locationName ||
                                  values?.locationName,
                                country: initialValues?.countryId || {
                                  id: values?.countryId,
                                },
                                countryId: initialValues?.countryId || {
                                  id: values?.countryId,
                                },
                                region: initialValues?.regionId || {
                                  id: values?.regionId,
                                },
                                city: initialValues?.cityId || {
                                  id: values?.cityId,
                                },
                                district: initialValues?.districtId || {
                                  id: values?.districtId,
                                },
                              }
                            : {
                                name:
                                  getFullLocationName(storageDestination) ||
                                  values?.locationName,
                                country: storageDestination?.country?.id || {
                                  id: values?.countryId,
                                },
                                region: storageDestination?.region?.id || {
                                  id: values?.regionId,
                                },
                                city: storageDestination?.city?.id || {
                                  id: values?.cityId,
                                },
                                district: storageDestination?.district?.id || {
                                  id: values?.districtId,
                                },
                              }
                        }
                        error={errors.locationName}
                        touched={touched.locationName}
                        destinationModalClasses={destinationModalClasses}
                        divider
                      />
                    </HomeTabCard>
                  )}
                  <HomeTabCard
                    className={
                      isMarketingPage
                        ? `${styles.marketingTabCard} ${cardClass}`
                        : cardClass
                    }
                    contentClassName="w-100"
                    title="# Guests & Rooms"
                    divider={!isMarketingPage}
                  >
                    <GuestsInputRedesigned
                      showErrors
                      errors={errors}
                      touched={touched}
                      values={values}
                      changeMathValue={changeMathValue}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      isMarketingPage={isMarketingPage}
                      divider
                      withRooms
                    />
                  </HomeTabCard>
                  <HomeTabCard
                    className={`${styles.marketingRoomCard} ${cardClass}`}
                    title="Rooms"
                    divider
                  >
                    <LandingRooms
                      values={values}
                      setFieldValue={setFieldValue}
                      changeMathValue={changeMathValue}
                    />
                  </HomeTabCard>
                  <HomeTabCard
                    className={
                      isMarketingPage
                        ? `${styles.marketingTabCard} ${cardClass}`
                        : cardClass
                    }
                    divider
                    cardClass={cardClass}
                    title="Start & End Dates"
                  >
                    <DateInputRedesigned
                      setFieldValue={setFieldValue}
                      values={values}
                      calendarFields={['checkin', 'checkout']}
                      errors={errors}
                      setIsDateError={setIsDateError}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      saveToStore
                      withDateRangeValidation={false}
                    />
                  </HomeTabCard>
                  <BudgetCard
                    divider
                    values={values}
                    setFieldValue={setFieldValue}
                    priceTypeOptions={BUDGET_PROPERTY_STAY}
                    priceTypeDisplayOptions={BudgetPeriodStayDisplay}
                    errors={errors}
                    className={isMarketingPage ? styles.marketingTabCard : null}
                    enablePriceType={!isMarketingPage}
                    isMarketingPage={isMarketingPage}
                    title="Preferred Budget"
                  />
                </HomeTabSection>
                {isMarketingPage && (
                  <Button
                    onClick={onClickFindTheBest}
                    disabled={!initValues?.name || isDateError || !isValid}
                    type="submit"
                    classes={`${styles.submitButton} ${styles.marketingSubmitButton} t-600 btn btn_primary-danger uppercased-text`}
                  >
                    Find The Best
                  </Button>
                )}
              </div>
              {!isModal && (
                <ExploreDestinationCard
                  properties
                  handleSubmit={handleSubmitForm}
                  destination={destination}
                  categoriesFilters={values.propertyType}
                  setTab={setTab}
                  storageDestination={storageDestination}
                />
              )}
              {!isMarketingPage && (
                <HomeTabSection
                  className={`${styles.filtersPanel} ${
                    withInfo && styles.withInfo
                  }`}
                  cardClass={cardClass}
                >
                  <div className={`${styles.filtersButtonContainer}`}>
                    <Priorities
                      inputs={accommodationsFormInputs}
                      initialValues={accommodationsInitialValues}
                      handleSubmit={handleSubmitForm}
                      displayComfortAndServiceTooltip={false}
                    />
                    <AdjustPropertyType
                      divider
                      initialValues={{
                        focus: initialValues.focus,
                        propertyType: initialValues.propertyType,
                      }}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <OtherFiltersAccommodations
                      inputs={FILTERS_MODAL_FIELDS}
                      handleSubmit={handleFiltersSubmit}
                    />
                    <Lounge
                      initialValues={{
                        freeBreakfastInLounge:
                          initialValues.freeBreakfastInLounge,
                        alcoholicDrinksInLounge:
                          initialValues.alcoholicDrinksInLounge,
                        foodAndNonAlcoholicDrinks:
                          initialValues.foodAndNonAlcoholicDrinks,
                      }}
                      values={values}
                      inputs={LOUNGE_SECTION_OPTIONS}
                      setFieldValue={setFieldValue}
                      headerTooltip={EXECUTIVE_LOUNGE_INFO_TOOLTIP}
                    />
                  </div>

                  <Button
                    onClick={onClickFindTheBest}
                    disabled={
                      !destination?.locationName || !isValid || isDateError
                    }
                    type="submit"
                    classes={`${styles.submitButton} t-600 btn btn_primary-danger uppercased-text ${styles.submitButtons}`}
                  >
                    Find The Best
                  </Button>
                </HomeTabSection>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default StaysTab;
