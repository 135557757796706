import React, { useCallback, useEffect, useState } from 'react';

import qs from 'qs';
import Button from '../UI/Button/Button';

import styles from './ExploreDestinationLinkButton.module.scss';

function ExploreDestinationLinkButton({
  buttonText,
  serviceGet,
  onClick,
  destination,
  redirectUrl,
  categoriesFilters = [],
  isActivities = true,
}) {
  const [count, setCount] = useState(null);

  const getRedirectQuery = useCallback(() => qs.stringify({
    ...(destination?.countryId && { countryId: destination?.countryId }),
    ...(destination?.regionId && { regionId: destination?.regionId }),
    ...(destination?.cityId && { cityId: destination?.cityId }),
    ...(destination?.districtId && { districtId: destination?.districtId }),
    ...(destination?.locationName && { name: destination?.locationName }),
    ...(categoriesFilters.length && isActivities && { categoriesFilters }),

  }), [destination, categoriesFilters]);

  const getCount = useCallback((params) => {
    const { locationName: deleted, ...newParams } = params;
    let model = { ...newParams };
    if (categoriesFilters.length && isActivities) {
      model = { ...model, categoriesFilters };
    }
    serviceGet(model).then((res) => {
      const { pagination: { totalCount } } = res;
      setCount(totalCount);
    });
  }, [categoriesFilters]);

  useEffect(() => {
    if (destination?.locationName) getCount(destination);
  }, [destination, categoriesFilters]);

  return (
    <Button
      type="button"
      onClick={onClick}
      classes="flex-shrink-0 t-600 btn btn_primary-link-light p-0 ml-1 "
      disabled={!destination?.locationName}
    >
      <a
        href={redirectUrl + getRedirectQuery()}
        target="_blank"
        rel="noreferrer noopener"
        className={`${!destination?.locationName && styles.linkDisable} btn btn_primary-link-light`}
      >
        { destination?.locationName ? (count > 0 ? count : '--') : ''}
        {' '}
        {buttonText}
      </a>
    </Button>
  );
}

export default ExploreDestinationLinkButton;
