import React, { useCallback, useEffect, useState } from 'react';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import PropertiesType from './PropertiesType/PropertiesType';
import { FOCUS_DROPDOWN_OPTIONS } from '../../constans/search';
import { PROPERTY_CATEGORY_OPTIONS } from '../../constans/property';
import { objectToHash } from '../../helpers/helper-methods';

import styles from './AdjustPropertyType.module.scss';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';

function AdjustPropertyType({
  initialValues,
  setFieldValue,
  values,
  radioLabelClassName = `col-sm-3 p-0 ${styles.radioLabel}`,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAllSelect, setIsAllSelect] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const toggleIsAllSelect = (isSelectAll, fieldName) => {
    if (!isAllSelect) {
      setFieldValue(fieldName, PROPERTY_CATEGORY_OPTIONS.map(({ value }) => value));
    } else {
      setFieldValue(fieldName, []);
    }
    setIsAllSelect((prev) => !prev);
  };

  const onDeselectItem = useCallback((fieldValue) => {
    if (
      objectToHash(fieldValue)
      !== objectToHash(PROPERTY_CATEGORY_OPTIONS.map(({ value }) => value))
    ) {
      setIsAllSelect(false);
    }
  });

  const checkIsDirty = (formValues) => {
    let count = 0;
    Object.keys(initialValues).forEach((key) => {
      if (Array.isArray(initialValues[key])) {
        if (objectToHash(initialValues[key]) !== objectToHash(formValues[key])) {
          count += Math.abs(initialValues[key].length - formValues[key].length);
        }
      } else if (initialValues[key] !== formValues[key]) {
        count += 1;
      }
    });
    setIsDirty(count > 0);
  };

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const onResetButtonClick = () => {
    Object.keys(initialValues).forEach((key) => {
      setFieldValue(key, initialValues[key]);
    });
    setIsDirty(false);
  };

  const onSubmitButtonClick = () => {
    toggleModal();
  };

  useEffect(() => {
    if (values) {
      checkIsDirty(values);
    }
  }, [values]);

  return (
    <>
      <HomeTabCardModal
        title="Properties type"
        show={isModalOpen}
        displayToggle={toggleModal}
        onClickResetButton={onResetButtonClick}
        onClickSubmitButton={onSubmitButtonClick}
        resetDisable={!isDirty}
      >
        <PropertiesType
          values={values}
          onDeselect={onDeselectItem}
          setFieldValue={setFieldValue}
          isAllSelected={isAllSelect}
          toggleIsAllSelect={toggleIsAllSelect}
          radioLabelClassName={radioLabelClassName}
          contestField={{ name: 'focus', options: FOCUS_DROPDOWN_OPTIONS }}
          propertyTypeField={{ name: 'propertyType', options: PROPERTY_CATEGORY_OPTIONS }}
        />
      </HomeTabCardModal>
      <LandingFiltersButton onClick={toggleModal}>
        Property type
      </LandingFiltersButton>
    </>
  );
}

export default AdjustPropertyType;
