import React from 'react';
import { FieldArray } from 'formik';

import { MAX_KIDS } from '../../constans/search';
import { integerPipeWithLimits, noop } from '../../helpers/helper-methods';
import CounterInputBlockRedesigned from '../CounterInputBlockRedesigned';
import { Input } from '../UI/Input/Input';
import DropdownWithModal from '../DropdownWithModal';
import {
  MAX_ADULT_TRAVELLER_AGE,
  MIN_ADULTS,
  MIN_ADULT_TRAVELLER_AGE,
  ROOM_MAX_PEOPLE,
} from '../../constans/validation';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import ValidationTooltip from '../ValidationTooltip/ValidationTooltip';

import styles from './index.module.scss';
import { KID_AGE_REQUIRED } from '../../constans/error-messages';

const kidsAgeOptions = Array
  .from(Array(18).keys())
  .map((option) => ({ label: option || '< 1', value: option }));

function GuestsInputRedesigned({
  values,
  setFieldValue,
  errors,
  showErrors,
  changeMathValue,
  kidsAgeInputName = 'kids',
  adultAgeInputName = 'adults',
  kidsCountInputName = 'kidGuests',
  adultsCountInputName = 'adultGuests',
  ADULT_PREFIX = 'adult',
  KIDS_PREFIX = 'children',
  placeholder = 'Add guests',
  minAdultsCount = MIN_ADULTS,
  setFieldTouched = noop,
  withRooms = false,
}) {
  const displayValueFromDropDownInputs = (adultsAmount, kidsAmount = '') => {
    const singularOrPluralAdults = adultsAmount > 1 ? `${ADULT_PREFIX}s` : ADULT_PREFIX;
    const singularOrPluralChildren = kidsAmount > 1 ? `${KIDS_PREFIX}` : 'child';
    const singularlyOrPlural = values?.rooms > 1 ? 'rooms' : 'room';

    return (
      `${adultsAmount ? `${adultsAmount} ${singularOrPluralAdults}` : ''}`
      + `${kidsAmount ? `${adultsAmount ? ',' : ''} ${kidsAmount} ${singularOrPluralChildren}` : ''}`
        + `${values?.rooms > 0 ? ` - ${values?.rooms} ${singularlyOrPlural}` : ''}`
    );
  };

  return (
    <>
      <ValidationTooltip isShow={errors?.[kidsCountInputName]}>
        {errors?.[kidsCountInputName]}
      </ValidationTooltip>
      <ValidationTooltip
        isShow={errors?.[kidsAgeInputName]?.includes(KID_AGE_REQUIRED) && !errors?.[kidsCountInputName]}
      >
        {errors?.[kidsAgeInputName]?.[errors?.[kidsAgeInputName]?.indexOf(KID_AGE_REQUIRED)]}
      </ValidationTooltip>
      <DropdownWithModal
        hideIcon
        darkValueColor
        errored={showErrors && errors?.[kidsAgeInputName]?.length > 0}
        id="guests"
        placeHolder={placeholder}
        inputValues={
        displayValueFromDropDownInputs(
          values[adultsCountInputName],
          values[kidsCountInputName],
        )
      }
        displayedValueClassName={styles.displayedValue}
        dropdownClassName={styles.dropdown}
        containerClasses={styles.guestsInput}
      >
        <div className="flex flex-col">
          <FieldArray
            validateOnChange
            name={adultAgeInputName}
            render={({ push, pop }) => {
              const handleIncrease = () => {
                changeMathValue(adultsCountInputName, Number(values[adultsCountInputName]), 'up');
                push('');
              };

              const handleDecrease = () => {
                changeMathValue(adultsCountInputName, Number(values[adultsCountInputName]));
                pop();
              };

              return (
                <>
                  { withRooms
                  && (
                  <div className={`flex flex-v-center flex-between ${styles.adultGuests}`}>
                    <div className={styles.label}>
                      Rooms
                    </div>
                    <CounterInputBlockRedesigned
                      className={`text-center pe-none ${styles.darkText}`}
                      increaseValue={() => changeMathValue('rooms', Number(values.rooms), 'up')}
                      reduceValue={() => changeMathValue('rooms', Number(values.rooms))}
                      reduceDisabled={!values?.rooms}
                      buttonsClassName="flex flex-column flex-center"
                      inputName="rooms"
                    />
                  </div>
                  )}
                  <div className={`flex flex-v-center flex-between ${styles.adultGuests}`}>
                    <div className={styles.label}>
                      Adult
                      <span>(18 and above)</span>
                    </div>
                    <CounterInputBlockRedesigned
                      inputMaxLength={ROOM_MAX_PEOPLE}
                      labelText="Adults"
                      reduceValue={handleDecrease}
                      increaseValue={handleIncrease}
                      inputName={adultsCountInputName}
                      inputClassName="inputStyles.input"
                      reduceDisabled={values[adultsCountInputName] <= minAdultsCount}
                      increaseDisabled={values[adultsCountInputName] >= ROOM_MAX_PEOPLE}
                    />
                  </div>
                  {!!values[adultsCountInputName] && (
                  <div className={styles.adults}>
                    <div className={styles.ageInputsLabel}>Adult age (optional)</div>
                    <div className={styles.ageInputs}>
                      {values?.[adultAgeInputName]?.map((_, index) => (
                        <Input
                          key={index}
                          containerStyles={styles.kidsInput}
                          onBlur={(e) => setFieldValue(
                            `${adultAgeInputName}.${index}`,
                            integerPipeWithLimits({
                              value: e.target.value,
                              maxValue: MAX_ADULT_TRAVELLER_AGE,
                              minValue: MIN_ADULT_TRAVELLER_AGE,
                            }),
                          )}
                          placeholder="-"
                          name={`${adultAgeInputName}.${index}`}
                          type="text"
                        />
                      ))}
                    </div>
                  </div>
                  )}
                </>
              );
            }}
          />

          <FieldArray
            validateOnChange
            name={kidsAgeInputName}
            render={({ push, remove }) => {
              const handleIncrease = () => {
                changeMathValue(kidsCountInputName, Number(values[kidsCountInputName]), 'up');
                push('');
                setFieldTouched(`${kidsAgeInputName}.${values[kidsAgeInputName].length}`);
              };

              const handleDecrease = () => {
                changeMathValue(kidsCountInputName, Number(values[kidsCountInputName]));
                remove(values[kidsAgeInputName].length - 1);
              };

              return (
                <>
                  <div className={`flex flex-v-center flex-between ${styles.childGuests}`}>
                    <div className={styles.label}>
                      Child
                      <span>{'(<1 - 17)'}</span>
                    </div>
                    <CounterInputBlockRedesigned
                      inputMaxLength={MAX_KIDS}
                      labelText="Kids"
                      inputName={kidsCountInputName}
                      inputClassName="inputStyles.input"
                      reduceValue={handleDecrease}
                      increaseValue={handleIncrease}
                      reduceDisabled={!values[kidsCountInputName]}
                      increaseDisabled={values[kidsCountInputName] >= ROOM_MAX_PEOPLE}
                    />
                  </div>

                  <hr className={styles.divider} />

                  {!!values[kidsCountInputName] && (
                  <div className={styles.kids}>
                    <div className={styles.ageInputsLabel}>Child age (mandatory)</div>
                    <div className={styles.ageInputs}>
                      {values?.[kidsAgeInputName]?.map((_kid, index) => (
                        <div key={index}>
                          <CustomSelect
                            placeholder={`Child ${index + 1}`}
                            options={kidsAgeOptions}
                            name={`${kidsAgeInputName}.${index}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  )}
                </>
              );
            }}
          />

        </div>
      </DropdownWithModal>
    </>
  );
}

export default GuestsInputRedesigned;
