import { currency as commonCurrency } from './form-fields';

export const MIN_BUDGET_PRICE = 0;
export const MAX_BUDGET_PRICE = 100000000;

export const BudgetPeriod = {
  ENTIRE_TRIPE: 'ENTIRE_TRIPE',
  PER_DAY: 'PER_DAY',
};

export const BudgetPeriodDisplay = {
  ENTIRE_TRIPE: 'trip',
  PER_DAY: 'day',
};

export const BudgetPeriodStay = {
  ENTIRE_STAY: 'ENTIRE_STAY',
  PER_NIGHT: 'PER_NIGHT',
};

export const BudgetPeriodStayDisplay = {
  ENTIRE_STAY: 'stay',
  PER_NIGHT: 'night',
};

const priceType = {
  initialValue: BudgetPeriod.ENTIRE_TRIPE,
  name: 'priceType',
  values: [
    { label: 'Per trip (activities only)', value: BudgetPeriod.ENTIRE_TRIPE },
    { label: 'Per day (activities only)', value: BudgetPeriod.PER_DAY },
  ],
};

const priceTypeStay = {
  initialValue: BudgetPeriodStay.PER_NIGHT,
  name: 'priceType',
  values: [
    { label: 'Total budget for stay', value: BudgetPeriodStay.ENTIRE_STAY },
    { label: 'Budget per night', value: BudgetPeriodStay.PER_NIGHT },
  ],
};

const currency = {
  ...commonCurrency,
  initialValue: 'EUR',
};

const minPrice = {
  initialValue: 100,
  name: 'minPrice',
  label: 'Min price',
};

const maxPrice = {
  initialValue: 200,
  name: 'maxPrice',
  label: 'Max price',
};

export const BUDGET_PROPERTY = {
  currency,
  minPrice,
  maxPrice,
  priceType,
};

export const BUDGET_PROPERTY_STAY = {
  currency,
  minPrice,
  maxPrice,
  priceType: priceTypeStay,
};

export const budgetFieldsInitialValues = Object.keys(BUDGET_PROPERTY).reduce(
  (acc, curr) => ({
    ...acc,
    [BUDGET_PROPERTY[curr].name]: BUDGET_PROPERTY[curr].initialValue,
  }),
  {},
);

export const budgetFieldsStayInitialValues = Object.keys(BUDGET_PROPERTY_STAY).reduce(
  (acc, curr) => ({
    ...acc,
    [BUDGET_PROPERTY_STAY[curr].name]: BUDGET_PROPERTY_STAY[curr].initialValue,
  }),
  {},
);

// eslint-disable-next-line no-shadow
export const getPriceRangeFromURL = (initValues, minPrice = null, maxPrice = null) => {
  const range = {};
  if (initValues?.minPrice || minPrice) {
    range.minPrice = initValues?.minPrice || minPrice;
  }
  if (initValues?.maxPrice || maxPrice) {
    range.maxPrice = initValues?.maxPrice || maxPrice;
  }
  return range;
};
